import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SectionWrap from "./Content";
import { Container, Col, Row, Form, Button} from "react-bootstrap";

class Footer extends React.Component {

  async sendMsg(e){
    try{
    e.preventDefault();
    const {Cname,Cemail,Cnumber,Cmsg}= e.target.elements;
    console.log(Cmsg.value)
    let details={
        site:"SparkHomesNJ",
        name:Cname.value,
        email:Cemail.value,
        phone:Cnumber.value,
        message:Cmsg.value
    }
    let response = await fetch("https://mdqzrv338i.execute-api.us-east-1.amazonaws.com/default/contactForm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    await response.json();
    alert("Message Sent, we'll be in touch!");
  }catch(e){
    alert("Whoops, couldn't send the message. Please try again later.");
  }
  }
  render() {
    return (
        <>
        <div align="center">
            <br/><br/><br/>
            <h1><a>Contact Me</a></h1>
           
            <Row style={{padding:80}}>
              <Col md={6} xs={12}> <br/>
                <h5><a href="mailto:info@uvgrade.com">info@uvgrade.com</a></h5><br/>
                <h5><a href="tel:9083014738">908-301-4738</a></h5>
              </Col>
              <Col md={6} xs={12} align="left">
                <Form onSubmit={this.sendMsg}>
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control id="Cname" type="text" required/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email:</Form.Label>
                  <Form.Control id="Cemail" type="email" required/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control id="Cnumber" type="number" required/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Message:</Form.Label>
                  <Form.Control id="Cmsg" as="textarea" rows={3} required/>
                </Form.Group>
                <Button id="formB" type="submit">Submit</Button>
              </Form>
              </Col>
            </Row>
            
            <br/><br/><br/><br/>
        </div>
      <SectionWrap background="bgGreen">
          <Container>
              <h6>©2021 by UVGrade d.b.a Spark Home Solutions. Find our parent company at UVGrade.com</h6>
            </Container>
      </SectionWrap>
      </>
    );
  }
}
export default Footer;
