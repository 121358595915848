import React from "react";
import SectionWrap, { Card3 } from "../components/Content";
import {Container, Row} from "react-bootstrap";
import biz1 from "../content/uewa.png"
import biz2 from "../content/webdev.png"
import biz3 from "../content/pc repair.jpg"
import biz4 from "../content/cloud.jpg"

class Biz extends React.Component {
  render() {
    return (
        <SectionWrap background="bgBlue">
          <div id="head">
            <br/> <br/>
          <h1>Business Services</h1> <br/>
          <h4>Expertise. Intuition. Ingenuity.</h4>
          <br/> <br/>
        </div>
       
        <br/> <br/> <br/> <br/>
        <Container>
        <Row style={{padding:50}}>
          <Card3
          title="Unifi Certified"
          body={<div>Want Enterprise grade Wi-Fi, Networking, and Cameras for your small business? <a href="https://www.ui.com/">Ubiquiti</a> is for you! Affordable pricing without requiring expensive licensing costs. Plus easy to use with an app once setup. Lets Talk!</div>}
          img={biz1}
          />
          <Card3
          title="Web Developers"
          body="Having a good web presence is crucial for any business. Especially being able to provide a fluid experience consistent on Desktop and Phone Browsers is critical. Let us hook you up!"
          img={biz2}
          />
          <Card3
          title="PC/Mac Support"
          body="Fixing PCs and Macs are our bread & butter! We come to you, or we can do drop offs. Hard drive swaps, screen replacement, let us provide you the white glove treatment in PC repair."
          img={biz3}
          />
          <Card3
          title="Cloud Compute Ready"
          body="Need an extra hand on a few side projects? Someone local who is familiar with cloud computing? Our devs have full time jobs working in this industry. Get expert help for your team at an incredible value!"
          img={biz4}
          />
        </Row>
        </Container>
        </SectionWrap>
    );
  }
}
export default Biz;
