import logo from './logo.svg';
import './App.css';
import Home from './pages/Home'
import Res from './pages/Res'
import Biz from './pages/Biz'
import About from './pages/About'
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import NotFound from "./pages/notfound";


import { Route, Switch, Link, BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
    <div id="body">
      <Navigation />
      <Switch>
        <Route exact path="/" component={Home} />
        
        <Route path="/residential" component={Res} />
        <Route path="/business" component={Biz} />
        <Route path="/about" component={About} />
        <Route path="*" component={Home} />

      </Switch>
      <Footer />
    </div>
  </Router>
  );
}

export default App;
