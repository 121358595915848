import React from "react";
//import { Link, animateScroll as scroll } from "react-scroll";
import { Container} from "react-bootstrap";
import SectionWrap from "../components/Content";
//import sparkhomes from "../content/home/sparkhomes.png";
//import fbook from "../content/flogo.png";
//import Section from "../components/Section";
//import ContactForm from "../components/ContactForm";

class About extends React.Component {
  
    render() {
        return (
          <SectionWrap background="bgBlue">
            <Container id="card4" style={{padding:80}}>
              <h1>About</h1>
              <br/><br/>
              <h3>Perfecting our Passion</h3><br/>
              <p>Located at the heart of Central Jersey (Princeton, NJ metro USA), our mission is to make technology friendly and convenient. We love technology and the work we do that we go the extra mile ensure you are happy with your installs. </p>
              <p>
                Spark Home Solutions is a subsidiary (d.b.a) of UVGrade, LLC. Spark Home Solutions is solely dedicated to local contracting work, registered in NJ. Our parent comany, UVGrade LLC, is an IT software company with a mission to make cool technical apps for the greater good. Check there for some other cool projects we are working on at <a href="https://www.uvgrade.com/">www.uvgrade.com</a>
              </p>
            </Container>

          </SectionWrap>
        );
      }
}
export default About;


