import React from "react";
import Carousel from "react-bootstrap/Carousel";
import shore from "../content/shore1.jpg";
import fiber from "../content/fiber.jpg"
import net from "../content/networking.png"
import net2 from "../content/uewa.png"
import jersey from "../content/jersey.jpg"

import {SectionWrap, Card1, Card2L} from "../components/Content";
class Home extends React.Component {
  render() {
    return (
      <div>
      <HTopic
          Img={shore}
          txt="Spark Home Solutions - serving the Garden State"
        />
      <SectionWrap background="bgGreen">
      <Card1
        title="Our Mission"
        sub="Your trusty neighbor"
        body="Whether its a large home or a small business, poor wifi or your laptop won't boot, we have your back when it comes to cost effective and powerful Tech Solutions."
        img={fiber}
      />
      </SectionWrap>
      <SectionWrap>
      <Card2L
        left={true}
        title="Serving Central Jersey"
        body="Conveniently located in the Princeton Metro, we are able to serve Central Jersey (yes it exists). From Newark Airport to the Jersey Shore, we've got your back!"
        img={jersey}
        />
        <Card2L
        title="Ubiquiti Wi-Fi Certified"
        body="Want something as robust as a Cisco network WITHOUT the complexity or licensing costs? Unifi by Ubiquiti to the RESCUE providing Wi-Fi, routing, switching, and security cameras, and we're certified =)"
        img={net}
        img2={net2}
        />
      </SectionWrap>
      </div>
    );
  }
}
export default Home;

function HTopic(props) {
  return (
    <Carousel>
      <Carousel.Item>
        <a href={props.url}>
          <img
            className="d-block w-100 text-center"
            src={props.Img}
            alt="See our other company"
          />
        </a>

        <Carousel.Caption>
          <h1>{props.txt}</h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
